import { Auth0Provider } from "@auth0/auth0-react"
import { navigate } from "gatsby"
import React from "react"

const onRedirectCallback = appState => {
  const goBackRedirect = window.sessionStorage.getItem("redirect")
  return navigate(goBackRedirect ?? appState?.returnTo ?? "/")
}

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain="kwitantie.eu.auth0.com"
      clientId="JNhmkvZXbtMzYLo21ReCoboNkMH5vkfu"
      redirectUri={window.location.origin + "/redirect"}
      onRedirectCallback={onRedirectCallback}
      advancedOptions
      scope="openid profile email read:current_user update:current_user_metadata"
      audience="https://kwitantie.eu.auth0.com/api/v2/"
    >
      {element}
    </Auth0Provider>
  )
}
