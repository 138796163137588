// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-belastingdienst-tsx": () => import("./../../../src/pages/belastingdienst.tsx" /* webpackChunkName: "component---src-pages-belastingdienst-tsx" */),
  "component---src-pages-factuur-voor-particulieren-tsx": () => import("./../../../src/pages/factuur-voor-particulieren.tsx" /* webpackChunkName: "component---src-pages-factuur-voor-particulieren-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-item-tsx": () => import("./../../../src/pages/item.tsx" /* webpackChunkName: "component---src-pages-item-tsx" */),
  "component---src-pages-overview-tsx": () => import("./../../../src/pages/overview.tsx" /* webpackChunkName: "component---src-pages-overview-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-voorbeeld-kwitantie-pdf-tsx": () => import("./../../../src/pages/voorbeeld-kwitantie-pdf.tsx" /* webpackChunkName: "component---src-pages-voorbeeld-kwitantie-pdf-tsx" */)
}

